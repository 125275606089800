import Vue from "vue";

Vue.directive("click-outside", {
  bind(element, binding, vnode) {
    const clickOutsideEvent = (event) => {
      // Check that click was outside the el and its children
      if (!(element === event.target || element.contains(event.target))) {
        // If it did, call method provided in attribute value
        vnode.context[binding.expression](event);
        // binding.value(); Run the argument
      }
    };
    document.body.addEventListener("click", clickOutsideEvent);
    // Make a shallow copy of the element parameter using the spread operator
    const elementCopy = { ...element };
    elementCopy.clickOutsideEvent = clickOutsideEvent;
  },
  unbind(element) {
    document.body.removeEventListener("click", element.clickOutsideEvent);
  },
});